import React from "react";
import { RoundedRect } from "../drawing/Rectangle";
import { LocationDirection, Side } from "../models/Common";
import { ImgRectangle } from "../drawing/ImgRectangle";

export interface IComponentInfo {
    id: number,
    color: string,
    description: string,
    img: any
}

export interface IComponentShape {
    width: number,
    height: number,
    centralOffsetX?: number,
    centralOffsetY?: number,
}

export interface IComponentLabels {
    dimensions?: {
        sides: Side[],
        diff: boolean,
    },
    description: boolean,
    id?: {
        locationDirection: LocationDirection,
        fromSide: Side,
        referenceShape: IComponentShape,
        perpendicularOffset?: number,
        padding?: number,
    }
}



/*
Použito pro všechny (asi) componenty (sideprofile, frontprofile, sensor...)


info ukládá ůdaje, který se pak printnout jako popis basecomponentů v /drawing
labels ukládá asi ty rozměrový čáry vedle/nad/pod výkresem
a shape ukládá asi rozměry co vykreslit



BaseComponent chce args IComponentInfo, IComponentShape, IComponentLabels
      
      IComponentInfo ukládá ůdaje, který se pak printnout jako popis basecomponentů v /drawing
        description je budto custom active area nebo active area
        color je barva, niž se vykreslí description
        id je číslo, jenz se vypiše před descritpion, coz teda nedává smysl, takže to bude asi ještě jinak
    
      IComponentLabel 
        dimensions ukládá asi ty rozměrový čáry vedle/nad/pod výkresem
          sides je enum z common Top, Right, Bottom, Left, takže pokud chci rozměrovou délku nahoře i dole, dám sides : [0,2]
          diff je MOŽNÁ, že pokud je to kratší než něco (asi než rozměry skla), tak vykreslím i ten rozdíl
            (takže active area bude mít tři délky; 1 za kolik začíná, 2 jak je dlouhá, 3 za kolik končí)
        description nvm, zatím jsem nenasel, že by u neceho nebyl false :((
        id je ten ťuplík s číslem ve výkresu
          locationDirection inward nebou outward
          fromSide z jaké strany má ťuplik jít
          padding jak dlouhá nozka k tupliku má být, kdyz nula, nerenderuje se vubec

      IComponentShape ukládá asi rozměry co vykreslit
        width
        height
        centralOffsetX kolik od středu n ose X offsetnout
        centralOffsetY to samý ale Y
*/
export class BaseComponent {
    public constructor(
        public info: IComponentInfo,
        public shape: IComponentShape,
        public labels: IComponentLabels)
    {
    }

    render(canvasW : number) {
        if(this.info.img) {
            
            return <ImgRectangle key={this.info.id} shape={this.shape} file={this.info.img} canvasW={canvasW}/>
        }else {
            
            return <RoundedRect key={this.info.id} shape={this.shape} info={this.info}/>
        }
    }
}