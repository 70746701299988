import { FieldProps, FormikProps, getIn, setIn } from "formik";
import { Orientation, isObject, parseFloat } from "./Common";
import { createContext, ReactNode } from "react";
import { standardSensors } from "./Sensor";
import equal from "react-fast-compare";
import { ProductType, ProductUsage } from "./Product";
import { AntiGlareType, Surface } from "./Customization";
import { defaultLogo } from "./Printing";
import { AspectRatio, DisplayConnection } from "./Display";
import { FortouchCFixedValues, ISolution, ISolutionConfig, OpticalBondingConstraints, isNumericRange } from "./Solution";
import { findField } from "./SolutionFields";
import { findRules } from "./SolutionRules";
import errorFields from "../form/ErrorFields";


export const defaultFormValues = {
    product: {
        type: ProductType.FortouchC,
        usage: ProductUsage.SemiOutdoor,
        pieces: 1,
        notes: '',
        title: '',
    },
    glass: {
        width: 750,
        height: 450,
        thickness: 5,
        isDouble: false,
        orientation: Orientation.Landscape,
    },
    customization: {
        corners: { tl: 0, tr: 0, bl: 0, br: 0 }, // mm
        cutouts: 0,
        surface: Surface.AntiGlare,
        antiGlareType: AntiGlareType.AG90,
        tempering: FortouchCFixedValues.customization?.tempering ?? true,
        lamination: FortouchCFixedValues.customization?.lamination ?? false,
        overheatingProtection: FortouchCFixedValues.customization?.overheatingProtection ?? false,
    },
    printing: {
        withPrinting: true,
        withLogo: true,
        frameThickness: { t: 0, b: 0, l: 0, r: 0 },
        objects: [{...defaultLogo}],
    },
    sensor: {
        isCustom: standardSensors[0].isCustom,
        diagonal: standardSensors[0].diagonal,
        width: standardSensors[0].width,
        height: standardSensors[0].height,
        activeArea: {
            width: standardSensors[0].activeArea.width,
            height: standardSensors[0].activeArea.height,
            centralOffsetX: standardSensors[0].activeArea.centralOffsetX,
            centralOffsetY: standardSensors[0].activeArea.centralOffsetY,
        },
        isPassiveAreaExact: standardSensors[0].isPassiveAreaExact,
        tail: {
            width: standardSensors[0].tail.width,
            length: standardSensors[0].tail.length,
            centralOffset: standardSensors[0].tail.centralOffset,
            attachment: standardSensors[0].tail.attachment,
        },
        cable: {
            height : 75,
        },
        orientation: Orientation.Landscape,
        passiveArea: {
            diagonal : 32,
            left : 0,
            right : 0,
            top : 0,
            bottom : 0
        },

    },
    display: {
        luminance: OpticalBondingConstraints.display?.luminance?.min ?? 0, // nits
        aspectRatio: AspectRatio.r16_9,
        connection: DisplayConnection.AdBoard,
    },
    customer: {
        company: '',
        creator: '',
        email: '',
    },
    status: {
        isApproved: false,
        fortesApprover: '',
        customerApprover: '',
    }
} as ISolution;


function apply(form: FormikProps<ISolution>, source: any, getValue: (currentValue: any, sourceValue: any) => any) {
    const stack : any[] = [];
    for (let attr in source)
        stack.push(attr);

    while (stack.length > 0) {
        const attr = stack.pop();
        if (!attr) continue; // shouldn't happen though

        const defaultValue = getIn(defaultFormValues, attr);
        const currentValue = getIn(form.values, attr);
        const sourceValue = getIn(source, attr);

        if (defaultValue === undefined ||           // disregard attributes not present in defaults
            sourceValue === null || sourceValue === undefined) // disregard empty values to keep the state of form components valid
            continue;

        if (isObject(sourceValue) && isObject(defaultValue)) {
            for (let a in sourceValue)
                stack.push(attr + "." + a);
        }
        else form.setFieldValue(attr, getValue(currentValue, sourceValue));
    }
}

export function applyConfig(form: FormikProps<ISolution>, { fixedValues, constraints }: ISolutionConfig) {
    apply(form, fixedValues, (_, sourceValue) => sourceValue);
    
    apply(form, constraints, (currentValue, sourceValue) => {
        if (sourceValue && isNumericRange(sourceValue))
            return Math.max(sourceValue.min ?? Number.NEGATIVE_INFINITY, Math.min(sourceValue.max ?? Number.POSITIVE_INFINITY, currentValue));

        if (sourceValue && Array.isArray(sourceValue))
            return sourceValue.length === 0 || sourceValue.includes(currentValue) ? currentValue : sourceValue[0];
        return currentValue;
    })
}

export const SolutionContext = createContext({...defaultFormValues} as ISolution);


/**
 * Comparing wheter a form is with default values
 * @param formValues form to compare with default values
 * @returns true if is w/ default values, false else
 */
export function isDefault(formValues: ISolution) {
    return equal(formValues, defaultFormValues);
}

export function isDefaultPrinting(formValues: ISolution) {
    return equal(formValues.printing, defaultFormValues.printing);
}

export function isDefaultLogos(formValues: ISolution) {
    return equal(formValues.printing?.objects, defaultFormValues.printing?.objects);
}

export function isDefaultDisplay(formValues: ISolution) {
    return equal(formValues.display, defaultFormValues.display);
}

export function applyRuleOnChange(form: FormikProps<ISolution>, e: React.ChangeEvent<HTMLInputElement>) {
    
   
    form.handleChange(e);

    
    const field = findField(e.target.name, 'getAttribute' in e.target ? (e.target.getAttribute('alternative') ?? undefined) : undefined);
    if (!field){
        
        
        return;
    }

    let fieldValue;
    if (field.type === "checkbox") {
        fieldValue = e.target.checked;
    } else {
        if (field.parse)
            fieldValue = field.parse(e.target.value);
        else if (field.type === "number")
            fieldValue = parseFloat(e.target.value);
        else
            fieldValue = e.target.value;
    }

    let values = structuredClone(form.values);
    

    
    values = setIn(values, field.name, fieldValue); // set field value right away; form.handleChange() doesn't have an immediate effect
    
    

    const rules = findRules(field.name);
    for (let rule of rules)
        rule.apply(values);

    const controlledFields = Array.from(new Set(rules.flatMap(r => r.controlledFields)));
    
    for (let fieldName of controlledFields) {
        const original = getIn(form.values, fieldName);
        const updated = getIn(values, fieldName);

        if (original !== updated)
            form.setFieldValue(fieldName, updated);
    }
}

export function getOnChange(props: FieldProps, fileArr?: any, index? : number) {
    

    if(!fileArr){

        return (e: React.ChangeEvent<HTMLInputElement>) => {
            if(!e.target.validity.valid) {if(errorFields[props.field.name] !== true) { errorFields[props.field.name] = true; }}
            else if(e.target.validity.valid) {if(errorFields[props.field.name] === true) { errorFields[props.field.name] = false; }}
            applyRuleOnChange(props.form, e);
        };
    }else{

        return (e: React.ChangeEvent<HTMLInputElement>) => {

  

            if(e.target.files && index !== undefined){

                fileArr.current[index] = e.target.files[0]

            }
                      
            applyRuleOnChange(props.form, e);
        };
    }
}

export function getOnChangeNumberInput(props: FieldProps) {
    return (event: React.FocusEvent<HTMLInputElement, Element> | React.PointerEvent<Element> | React.KeyboardEvent<Element>, value: number | undefined) => {
        
        event.preventDefault()
        const target = event.target as HTMLInputElement
        target.name = props.field.name

        applyRuleOnChange(props.form, {target, type: 'change'} as React.ChangeEvent<HTMLInputElement>)
    }
}

export function getOnChangeSelectInput(props: FieldProps) {
    return (event: { target: { value: string } }) => {
        
        //event.preventDefault()
        const target = event.target as HTMLInputElement
        target.name = props.field.name

        applyRuleOnChange(props.form, {target, type: 'change'} as React.ChangeEvent<HTMLInputElement>)
    }
}