import { Orientation } from "./Common";
import { doubleSurfaces, surfaces } from "./Customization";
import { complementary, findStandardSensor, getAlternativeAttachments, getCurrentAttachments, horizontalAttachments, isRotated, standardSensors, verticalAttachments } from "./Sensor";
import { ISolution } from "./Solution";
import { ISolutionField } from "./SolutionFields";

export interface ISolutionRule {
    controlFields: ISolutionField['name'][],
    controlledFields: ISolutionField['name'][],
    apply: (solution: ISolution) => void, // changes happen in place
}

export const changeSurfaceRule = {
    controlFields: [
        'product.type', // indirectly -- product.type affects glass.isDouble
        'glass.isDouble', // directly
    ],
    controlledFields: [
        'customization.surface',
    ],
    apply: (solution: ISolution) => {
        if (solution.customization) {
            solution.customization.surface = solution.glass.isDouble ? doubleSurfaces[0] : surfaces[0];
        }
    }
}

export const inferGlassOrientationRule = {
    controlFields: [
        'glass.width',
        'glass.height',
    ],
    controlledFields: [
        'glass.orientation',
    ],
    apply: (solution: ISolution) => {

        const w = solution.glass.width;
        const h = solution.glass.height;

        if (w && h) {
            const o = w >= h ? Orientation.Landscape : Orientation.Portrait;
            solution.glass.orientation = o;
        }
    }
} as ISolutionRule;

export const correctOverheatingProtection = {
    controlFields: [
        'customization.lamination',
    ],
    controlledFields: [
        'customization.overheatingProtection',
    ],
    apply: (solution: ISolution) => {
        if (solution.customization && !solution.customization.lamination)
            solution.customization.overheatingProtection = false;
    }
} as ISolutionRule;

export const inferStandardSensorRule = {
    controlFields: [
        'sensor.diagonal',
        'sensor.orientation',
    ],
    controlledFields: [
        'sensor.width',
        'sensor.height',
        'sensor.activeArea.centralOffsetX',
        'sensor.activeArea.centralOffsetY',
        'sensor.isPassiveAreaExact',
        'sensor.tail.centralOffset',
    ],
    apply: (solution: ISolution) => {
    
        const standard = findStandardSensor(solution.sensor.diagonal);
        if (!standard || solution.sensor.isCustom) return;
        
        const swap = isRotated(solution.sensor);
        const originalAttachments = getCurrentAttachments(standard);
        const altAttachments = getAlternativeAttachments(standard);

        let attachment = solution.sensor.tail.attachment;
        if (!swap && !originalAttachments.includes(attachment))
            attachment = originalAttachments[0];
        if (swap && !altAttachments.includes(attachment))
            attachment = altAttachments[0];

        const correctActiveArea = (!swap && attachment === standard.tail.attachment) || (swap && attachment === complementary(standard.tail.attachment)); 
        const coefX = !correctActiveArea && horizontalAttachments.includes(attachment) ? -1 : 1;
        const coefY = !correctActiveArea && verticalAttachments.includes(attachment) ? -1 : 1;

        solution.sensor.width = swap ? standard.height : standard.width;
        solution.sensor.height = swap ? standard.width : standard.height;
        solution.sensor.activeArea.width = swap ? standard.activeArea.height : standard.activeArea.width;
        solution.sensor.activeArea.height = swap ? standard.activeArea.width : standard.activeArea.height;
        solution.sensor.activeArea.centralOffsetX = swap ? coefX * (standard.activeArea.centralOffsetY ?? 0) : coefX * (standard.activeArea.centralOffsetX ?? 0);
        solution.sensor.activeArea.centralOffsetY = swap ? coefY * (standard.activeArea.centralOffsetX ?? 0) : coefY * (standard.activeArea.centralOffsetY ?? 0);
        solution.sensor.isPassiveAreaExact = standard.isPassiveAreaExact;
        solution.sensor.tail.width = standard.tail.width;
        solution.sensor.tail.length = standard.tail.length;
        solution.sensor.tail.centralOffset = standard.tail.centralOffset;
        solution.sensor.tail.attachment = attachment;
    }
} as ISolutionRule;

export const resetCustomSensorRule = {
    controlFields: [
        // this is only a helper rule applied by changeSensorTypeRule (see below)
    ],
    controlledFields: [
        'sensor.activeArea.centralOffsetX',
        'sensor.activeArea.centralOffsetY',
        'sensor.isPassiveAreaExact',
        'sensor.tail.centralOffset',
    ],
    apply: (solution: ISolution) => {    
        if (solution.sensor.isCustom) {
            solution.sensor.activeArea.centralOffsetX = 0;
            solution.sensor.activeArea.centralOffsetY = 0;
            solution.sensor.isPassiveAreaExact = false;
            solution.sensor.tail.centralOffset = 0;
        }
    }
} as ISolutionRule;

export const inferCustomSensorRule = {
    controlFields: [
        'sensor.activeArea.width',
        'sensor.activeArea.height',
    ],
    controlledFields: [
        'sensor.width',
        'sensor.height',
        'sensor.diagonal',
        'sensor.orientation',
    ],
    apply: (solution: ISolution) => {
        const sensor = solution.sensor;
        const aw = sensor.activeArea.width;
        const ah = sensor.activeArea.height;

        if (sensor.isCustom && aw && ah) {

            const diagonalInch = Math.sqrt(aw * aw + ah * ah) / 25.4;
            const orientation = aw >= ah ? Orientation.Landscape : Orientation.Portrait;

            // Add tentative passive margin, if it is not specified exactly
            if (!solution.sensor.isPassiveAreaExact) {

                let tentativePassiveMargin = 15;
                if (diagonalInch > 32 && diagonalInch <= 55)
                    tentativePassiveMargin = 20;
                else if (diagonalInch > 55)
                    tentativePassiveMargin = 25;

                solution.sensor.width = aw + 2 * tentativePassiveMargin;
                solution.sensor.height = ah + 2 * tentativePassiveMargin;
            }
            solution.sensor.diagonal = diagonalInch;
            solution.sensor.orientation = orientation;
        }
    }
} as ISolutionRule;

export const changeSensorTypeRule = {
    controlFields: [
        'sensor.isCustom',
    ],
    controlledFields: [
        'sensor.diagonal',
        ...Array.from(new Set([
            ...inferStandardSensorRule.controlledFields,
            ...resetCustomSensorRule.controlledFields,
            ...inferCustomSensorRule.controlledFields,
        ])),
    ],
    apply: (solution: ISolution) => {
        const isStandard = !solution.sensor.isCustom;
        const sensor = isStandard ? standardSensors[0] : solution.sensor;

        if (isStandard) {
            solution.sensor.diagonal = sensor.diagonal;
            inferStandardSensorRule.apply(solution);
        } else {
            resetCustomSensorRule.apply(solution);
            inferCustomSensorRule.apply(solution);
        }
    },
} as ISolutionRule;

export const correctCustomTailAlignmentRule = {
    controlFields: [
        'sensor.tail.centralOffset',
    ],
    controlledFields: [
        'sensor.tail.isCustom',
    ],
    apply: (solution: ISolution) => {
        const sensor = solution.sensor;
        const tail = sensor.tail;
        const standard = findStandardSensor(solution.sensor.diagonal);

        solution.sensor.tail.isCustom = (sensor.isCustom && tail.centralOffset !== 0)
            || (!sensor.isCustom && standard?.tail.centralOffset !== tail.centralOffset);
    }
}

// The rules are applied in the order of listing.
// A rule works with the results of previous rules
// -- the latest solution values.
export const solutionRules = [
    changeSurfaceRule,
    inferGlassOrientationRule,
    correctOverheatingProtection,
    inferStandardSensorRule,
    correctCustomTailAlignmentRule,
] as ISolutionRule[];

export function findRules(field: ISolutionField['name']) {
    return solutionRules.filter(r => r.controlFields.includes(field));
}
