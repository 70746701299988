import React from 'react';
import { NextButton } from './form/NextButton';
import { useNavigate } from "react-router-dom";

// Import any tick icon from a library like FontAwesome or use an emoji as an example.
const Confirm = ({ iconColor = '#250048', textColor = 'black' }) => {

    const navigate = useNavigate();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh', // Full viewport height to center vertically
                textAlign: 'center',
            }}>
            {/* Tick icon */}
            <div style={{ fontSize: '50px', color: iconColor }}>
                &#10003; {/* Checkmark symbol */}
            </div>
            {/* Confirmation message */}
            <div style={{ color: textColor, fontSize: '18px', marginTop: '10px', marginBottom: '50px' }}>
                Your drawing was submitted!
            </div>
            <NextButton onClick={() => navigate('/form')} text={'Create another drawing'} />
        </div>
    );
};

export default Confirm;