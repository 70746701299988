import { Box, FormControlLabel, Radio, RadioGroup, Typography, Tabs, Tab, tabClasses, tabsClasses, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { MouseEventHandler } from "react";

export interface IBinaryQuestionProps {
    text: string,
    value: boolean | '', // '' for clearing
    options?: [boolean, boolean]
    labels: [string, string],
    onClicks: [MouseEventHandler, MouseEventHandler], 
}
interface ITabProps {
    label: string,
    onClick: MouseEventHandler
}

export function BinaryQuestion(props: IBinaryQuestionProps) {
    const options = props.options ?? [true, false];

    return <Grid container alignItems="center">
    <Grid item xs={12} md={5}>
        <Typography paddingRight="20px" color="#250048" fontSize="100%" maxWidth="200px">{props.text}</Typography>
    </Grid>
    <Grid item xs={12} md={7}>
        <StyledTabs {...props} />
    </Grid>
</Grid>;
}

function StyledTabs(props: IBinaryQuestionProps) : JSX.Element {
    return (        
        <Tabs
        value={props.value == true ? 0 : 1}
        sx={{
            //height: 48,
            padding:"3px",
            minHeight:30,
            minWidth:20,
            width:140,
            borderRadius:60,
            zIndex: 2,
            backgroundColor: "#eef2f6",
            indicatorColor:"green",
            [`& .${tabsClasses.indicator}`]: {
                height: "100%",
                borderRadius: 20,
                backgroundColor: "#1a0549",
              }
        }}>
            <StyledTab label="Yes" onClick={props.onClicks[0]} />
            <StyledTab label="No" onClick={props.onClicks[1]}/>
        </Tabs>
    )
}

const StyledTab = styled(Tab)(({}) => ({
    zIndex: 1,
    textTransform: "none",
    color: "#1a0549",
    minHeight: 30,
    minWidth: 30,
    height: 40,
    width: 70,
    padding:0,
    [`&.${tabClasses.selected}`]: {
      color: "white",
    },
  }));