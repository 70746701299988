/*
Proc toto exituje?
funkce, ktery zadas cislo a vrati to samy cislo jako hodnotu
:)))
Uz vim - protoze nekdy je potreba prepsat jinou funkci (napr pri identityScale se nahrazuje identity() za scaleLinear())
*/
export const identity = (n: number) => n;

export enum LocationDirection {
    Inward, Outward
}

export enum Side {
    Top, Right, Bottom, Left
}

export function isVertical(side: Side) {
    return side === Side.Top || side === Side.Bottom;
}

export type IRadiusShorthand = number | { t: number, b: number } | IRadius;

export interface IRadius {
    tl: number,
    tr: number,
    bl: number,
    br: number,
}

export interface ISideOffsets {
    t: number,
    b: number,
    l: number,
    r: number
}

export type ISidePadding = ISideOffsets;

export function isObject(value: any) {
    return typeof value === 'object'
        && value !== null
        && !Array.isArray(value);
}

export enum Orientation {
    Landscape = "Landscape", Portrait = "Portrait"
}
export const orientations = [ Orientation.Landscape, Orientation.Portrait ];

export interface IDimensions2D {
    width: number, // mm
    height: number, // mm
}

export interface IOfsset2D {
    centralOffsetX: number, // mm
    centralOffsetY: number, // mm
}

export const floatStep = 0.1;

export function floatToStr(num: number) {
    return num.toLocaleString('en', { maximumFractionDigits: 1 });
}

export function parseFloat(input: any) {
    const num = Number.parseFloat(input);
    const str = floatToStr(num); // round
    return Number.parseFloat(str);
}

export function parseInt(input: any) {
    const num = Number.parseInt(input);
    return Math.round(num);
}

export type EnumType = {[key:string]: string | number}[];

export function parseEnum<E>(enumeration: E, value: string) {
    return enumeration[value as keyof typeof enumeration];
}