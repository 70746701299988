import { HTMLInputTypeAttribute } from "react";
import { CheckboxField } from "../form/Checkbox";
import MuiField from "../form/MuiField"
import { FieldProps } from "formik";
import { standardSensors, allAttachments, TailAttachment } from "./Sensor";
import { ProductType, ProductUsage, productTypes, productUsages } from "./Product";
import { EnumType, Orientation, orientations, parseEnum, parseInt } from "./Common";
import { AntiGlareType, antiGlareTypes, surfaceToStr, surfaces, parseSurface } from "./Customization";
import { AspectRatio, DisplayConnection, aspectRatios, displayConnections } from "./Display";

export interface ISolutionField<V = any> {
    name: string,
    alternative?: string,
    label: string,
    helperText?: string,
    variant?: 'select' | 'textarea' | 'notes',
    type?: HTMLInputTypeAttribute,
    parse?: (str: string) => V,
    valueToString?: (value: V) => string,
    component: (props: FieldProps) => JSX.Element, 
    values?: EnumType,
    allowNegative?: boolean,
    min?: number,
    max?: number,
    integer: boolean
}

export interface ISolutionFieldId {
    name: ISolutionField['name'],
    alternative: ISolutionField['alternative'],
}

export const solutionFields = [
    { name: "product.type", label: "Type", variant: "select", component: MuiField, parse: (s: string) => parseEnum(ProductType, s), values: productTypes },
    { name: "product.usage", label: "Usage", variant: "select", component: MuiField, parse: (s: string) => parseEnum(ProductUsage, s), values: productUsages },
    { name: "product.pieces", label: "Pieces", type: "number", component: MuiField, parse: parseInt, min:0, integer:true },
    { name: "product.notes", label: "Notes", variant: "notes", component: MuiField, helperText: "notes" },
    { name: "product.title", label: "Product title", variant: "textarea", component: MuiField, helperText: "Product title"},

    { name: "glass.width", label: "Width (mm)", type: "number", component: MuiField, step:10, max: 10000, min: 0 },
    { name: "glass.height", label: "Height (mm)", type: "number", component: MuiField, step:10, max:5000, min: 0 },
    { name: "glass.thickness", label: "Thickness (mm)", type: "number", component: MuiField, step:10, min: 0, max: 100},
    { name: "glass.orientation", label: "Orientation", variant: "select", component: MuiField, parse: (s: string) => parseEnum(Orientation, s), values: orientations },

    { name: "customization.corners.tr", label: "Top right", type: "rawNumber", component: MuiField, min: -0.01},
    { name: "customization.corners.tl", label: "Top left", type: "rawNumber", component: MuiField, min: -0.01},
    { name: "customization.corners.br", label: "Bottom right", type: "rawNumber", component: MuiField, min: -0.01},
    { name: "customization.corners.bl", label: "Bottom left", type: "rawNumber", component: MuiField, min: -0.01},

    { name: "customization.cutouts", label: "Number of cutouts", type: "number", component: MuiField, parse: parseInt, min: -1, integer:true },
    { name: "customization.surface", label: "Surface", variant: "select", component: MuiField, values: surfaces, parse: parseSurface, valueToString: surfaceToStr },
    { name: "customization.antiGlareType", label: "Anti-glare type", variant: "select", component: MuiField, values: antiGlareTypes, parse: (s: string) => parseEnum(AntiGlareType, s) },
    { name: "customization.tempering", paired: true, label: "Tempering", type: "checkbox", component: CheckboxField },
    { name: "customization.lamination", paired: true, label: "Lamination", type: "checkbox", component: CheckboxField },
    { name: "customization.overheatingProtection", paired: true, label: "Solar protection against overheating", type: "checkbox", component: CheckboxField },
    
    { name: "printing.frameThickness.l", label: "Left", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "printing.frameThickness.r", label: "Right", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "printing.frameThickness.t", label: "Top", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "printing.frameThickness.b", label: "Bottom", type: "rawNumber", component: MuiField, min: -0.01 },
    
    { name: "sensor.passiveArea.diagonal", label: "Diagonal (inches)", type: "number", component: MuiField, min: 0 },
    { name: "sensor.passiveArea.left", label: "Left", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "sensor.passiveArea.right", label: "Right", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "sensor.passiveArea.top", label: "Top", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "sensor.passiveArea.bottom", label: "Bottom", type: "rawNumber", component: MuiField, min: -0.01 },

    
    
    { name: "sensor.isCustom", label: "Create a custom sensor", type: "checkbox", component: CheckboxField },
    { name: "sensor.diagonal", alternative: "fixed", label: "Diagonal (\")", variant: "select", component: MuiField, values: standardSensors.map(s => s.diagonal) },
    { name: "sensor.diagonal", alternative: "flexible", label: "Diagonal (\")", type: "number", component: MuiField },
    { name: "sensor.width", label: "Total width (mm)", type: "number", component: MuiField },
    { name: "sensor.height", label: "Total height (mm)", type: "number", component: MuiField },
    { name: "sensor.orientation", label: "Orientation", variant: "select", component: MuiField, parse: (s: string) => parseEnum(Orientation, s), values: orientations },
    { name: "sensor.activeArea.width", paired:true, label: "Width", type: "number", component: MuiField, step:10, min: 0, max:10000 },
    { name: "sensor.activeArea.height", paired:true, label: "Height", type: "number", component: MuiField, step:10, min: 0, max:5000 },
    { name: "sensor.activeArea.centralOffsetX", label: "Active area center shift x (mm)", type: "number", component: MuiField },
    { name: "sensor.activeArea.centralOffsetY", label: "Active area center shift y (mm)", type: "number", component: MuiField },
    { name: "sensor.isPassiveAreaExact", label: "Passive area is specified exactly", type: "checkbox", component: CheckboxField },
    { name: "sensor.tail.attachment", paired:true, label: "Attachment", variant: "select", component: MuiField, parse: (s: string) => parseEnum(TailAttachment, s), values: allAttachments },
    { name: "sensor.tail.isCustom", label: "Use custom tail alignment (instead of center)", type: "checkbox", component: CheckboxField },
    { name: "sensor.tail.width", paired:true, label: "Width", type: "number", component: MuiField, step:5, min:9.99 },
    { name: "sensor.tail.length", paired:true, label: "Length", type: "number", component: MuiField, step:5, min:-0.01 },
    { name: "sensor.tail.centralOffset", paired:true, label: "Alignment", helperText: "Zero = center, negative = move left, positive = move right", type: "number", component: MuiField , step:5 },
    { name: "sensor.cable.height", paired:true, label: "Length", type: "number", component: MuiField, step:5, min: 19.99 },

    { name: "display.luminance", label: "Luminance (nits)", type: "number", component: MuiField },
    { name: "display.aspectRatio", label: "Aspect ratio", variant: "select", component: MuiField, parse: (s: string) => parseEnum(AspectRatio, s), values: aspectRatios },
    { name: "display.connection", label: "Connection", variant: "select", component: MuiField, parse: (s: string) => parseEnum(DisplayConnection, s), values: displayConnections },

    { name: "customer.company", label: "Company", type: "text", component: MuiField, helperText: "your company name" , required: true},
    { name: "customer.creator", label: "Name", type: "text", component: MuiField, helperText: "your name", required: true},
    { name: "customer.email", label: "E-mail", type: "email", component: MuiField, helperText: "your@email.com", required: true},

    { name: "status.isApproved", label: "Order is approved", type: "checkbox", component: CheckboxField },
    { name: "status.fortesApprover", label: "Approved by FORTES", type: "text", component: MuiField },
    { name: "status.customerApprover", label: "Approved by customer", type: "text", component: MuiField },
] as ISolutionField[];

export function findField(name: string, alternative?: string) {
    return solutionFields.find(f => f.name === name && (!alternative || f.alternative === alternative));
}
