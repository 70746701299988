import { ISensor } from "./Sensor";
import { IProduct, ProductType, ProductUsage } from "./Product";
import { IGlass, otherThickness } from "./Glass";
import { IGlassCustomization, doubleSurfaces, surfaces } from "./Customization";
import { IDisplay } from "./Display";
import { ICustomer } from "./Customer";
import { IStatus } from "./Status";
import { IPrinting } from "./Printing";


export interface ISolution {
    product: IProduct,
    glass: IGlass,
    customization: IGlassCustomization | null,
    printing: IPrinting | null,
    sensor: ISensor,
    display: IDisplay | null,
    customer: ICustomer,
    status: IStatus,
}

export interface IRange<T> {
    min?: T, // including
    max?: T, // including
}
export type INumericRange = IRange<number>;

export function isRange(value: any): value is IRange<any> {
    return "min" in value || "max" in value;
}

export function isNumericRange(value: any): value is INumericRange {
    return isRange(value) && typeof value.min === 'number' && typeof value.max === 'number';
}

/**
 * Defines constraints for all attributes of an object of type T. 
 * An attribute can either be either numeric or value-based (e.g., enum).
 * Numeric attributes can be constrained to a range of [min, max] (incl.).
 * Value-based attributes can be constrained to a specific set of values.
 * Constraining each attribute is optional.
 */
type IConstraints<T> = {
    [Attribute in keyof T]?: T[Attribute] extends number ? INumericRange : T[Attribute][];
}
/**
 * Defines constraints for each top-level attribute of ISolution.
 * Constraining each attribute is optional.
 */
export type ISolutionConstraints = {
    [Attribute in keyof ISolution]?: IConstraints<ISolution[Attribute]>;
}

export const defaultConstraints = {
    product: {
        pieces: { min: 1 },
    },
    glass: {
        width: { min: 1 },
        height: { min: 1 },
    },
    sensor: {
        diagonal: { min: 1 },
    },
} as ISolutionConstraints;


export const FortouchOFixedValues = {
    product: {
        usage: ProductUsage.Outdoor,
    },
    glass: {
        isDouble: true,
    },
    customization: {
        tempering: true,
        lamination: true,
    },
    display: null,
} as Partial<ISolution>;

export const FortouchOConstraints = {
    ...defaultConstraints,
    glass: {
        thickness: [2, 3, 4, otherThickness],
    },
    customization: {
        surface: doubleSurfaces,
    },
    sensor: {
        diagonal: { min: 7, max: 110 },
    },
} as ISolutionConstraints;


export const FortouchSFixedValues = {
    product: {
        usage: ProductUsage.Indoor,
    },
    glass: {
        isDouble: false,
    },
    customization: {
        tempering: true,
        lamination: false,
        overheatingProtection: false,
    },
    display: null,
} as Partial<ISolution>;

export const FortouchSConstraints = {
    ...defaultConstraints,
    glass: {
        thickness: [ 2, 3, 4, 5, 6 ],
    },
    customization: {
        surface: surfaces,
    },
    sensor: {
        diagonal: { min: 7, max: 86 },
    },
} as ISolutionConstraints;


export const FortouchCFixedValues = {
    glass: {
        isDouble: false,
    },
    customization: {
        tempering: true,
        overheatingProtection: false,
    },
    display: null,
} as Partial<ISolution>;

export const FortouchCConstraints = {
    ...defaultConstraints,
    product: {
        usage: [ ProductUsage.Indoor, ProductUsage.Outdoor, ProductUsage.SemiOutdoor ],
    },
    glass: {
        thickness: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
    customization: {
        surface: surfaces,
    },
    sensor: {
        diagonal: { min: 7, max: 110 },
    },
} as ISolutionConstraints;


export const OpticalBondingFixedValues = {
    customization: null,
    printing: null,
} as Partial<ISolution>;

export const OpticalBondingConstraints = {
    ...defaultConstraints,
    product: {
        usage: [ProductUsage.Indoor, ProductUsage.Outdoor, ProductUsage.SemiOutdoor],
    },
    glass: {
        thickness: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // FIX ME: check with Libor
    },
    customization: null,
    sensor: {
        diagonal: { min: 7, max: 32 },
    },
    display: {
        luminance: { min: 450, max: 2500 },
    },
} as ISolutionConstraints;


export interface ISolutionConfig {
    fixedValues: Partial<ISolution>,
    constraints: ISolutionConstraints,
}

export function getSolutionConfig(productType: ProductType): ISolutionConfig {
    switch (productType) {
        case ProductType.FortouchO:
            return { fixedValues: FortouchOFixedValues, constraints: FortouchOConstraints };
        case ProductType.FortouchS:
            return { fixedValues: FortouchSFixedValues, constraints: FortouchSConstraints };
        case ProductType.FortouchC:
            return { fixedValues: FortouchCFixedValues, constraints: FortouchCConstraints };
        case ProductType.OpticalBonding:
            return { fixedValues: OpticalBondingFixedValues, constraints: OpticalBondingConstraints };
    }
}
