import { Button } from "@mui/material"

interface IPreviousProps {
  onClick: () => void
}

export function PreviousButton(props : IPreviousProps): JSX.Element {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        paddingInline: "20px",
        height:"45px",
        justifySelf: "left",
        textTransform: "none",
        borderRadius: "999px",
        color: "#250048",
        backgroundColor:"#edf2f6",

        '&:hover': {
          color: "#edf2f6",
          backgroundColor:"#250048",
        }
      }}>
        previous
      </Button> 
  )
}