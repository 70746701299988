import { Box, Grid, Typography } from "@mui/material";
import { SolutionField } from "./SolutionField";
import { BorderBottom, BorderLeft, BorderRight, BorderTop } from "@mui/icons-material";


export function BorderSpacing(props : {
  label: string, 
  topSolutionFiledName: string, 
  bottomSolutionFiledName: string, 
  leftSolutionFiledName: string, 
  rightSolutionFiledName: string
}) : JSX.Element {
  return (
    <Grid container>
      <Grid xs={12} md={5}>
          <Typography paddingRight="20px" color="#250048" fontSize="100%" maxWidth="200px">{props.label}</Typography>
      </Grid>
      <Grid xs={12} md={7} container justifyContent="center">
        
          <AlignPair direction="column" firstEl={<Box sx={{marginTop: "3px"}}><BorderTop/></Box>} secondEl={<SolutionField name={props.topSolutionFiledName} />} />

          <Grid container direction="row" justifyContent="center" alignItems="center">

            <AlignPair direction="row" firstEl={<Box sx={{marginTop: "3px"}}><BorderLeft/></Box>} secondEl={<SolutionField name={props.leftSolutionFiledName} />}/>
            <AlignPair direction="row" firstEl={<SolutionField name={props.rightSolutionFiledName}/>} secondEl={<Box sx={{marginTop: "3px"}}><BorderRight/></Box>}/> 

          </Grid>
          
          <AlignPair direction="column" firstEl={<SolutionField name={props.bottomSolutionFiledName} />} secondEl={<Box sx={{marginTop: "3px"}}><BorderBottom/></Box>}/>   

      </Grid>
    </Grid>
  )
}

function AlignPair(props : {
  direction: "column" | "row",
  firstEl: JSX.Element,
  secondEl: JSX.Element
}) : JSX.Element {
  return (
    <Box sx={{
      margin:"3px"
    }}>
      <Grid container direction={props.direction} alignItems="center">
        <Grid>
          {props.firstEl}
        </Grid>
        <Grid>
          {props.secondEl}
        </Grid>
      </Grid>
    </Box>
  )
}