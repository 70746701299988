import React from "react";
import { RoundedRect } from "../drawing/Rectangle";
import { LocationDirection, Side } from "../models/Common";
import { ISolution } from "../models/Solution";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { Glass } from "./Glass";

export class GlassProfile extends BaseComponent {
    private parts?: IComponentShape[];

    public constructor(id: number, solution: ISolution, glass: Glass, centralOffsetX: number) {
        let glassThickness = solution.glass.thickness;
        // sensor thickness + possibly IR
        let sensorThickness = solution.customization?.overheatingProtection ? 1 : 0.7;

        let overallThickness = solution.glass.thickness;
        if (solution.glass.isDouble) {
            overallThickness *= 2; // double glass
            overallThickness += sensorThickness;
        }

        super({
            id,
            color: "#000",
            description: glass.info.description,
        } as IComponentInfo,
        {
            width: glass.shape.width,
            height: overallThickness,
            centralOffsetX: centralOffsetX,
        } as IComponentShape, // overall shape
        {
            dimensions: {
                sides: [ Side.Right ],
                diff: false,
            },
            id: {
                locationDirection: LocationDirection.Outward,
                fromSide: Side.Top,
                padding: 10,
            },
            description: false,
        } as IComponentLabels);

      
        if (solution.glass.isDouble) {
            this.parts = [
                {
                    width: this.shape.width,
                    height: glassThickness,
                    centralOffsetX: this.shape.centralOffsetX,
                },
                // The drawn sensor layer is thinner than its stroke, so skip it
                // {
                //     width: this.shape.width,
                //     height: sensorThickness,
                //     centralOffsetX: this.shape.centralOffsetX,
                //     centralOffsetY: glassThickness,
                // },
                {
                    width: this.shape.width,
                    height: glassThickness,
                    centralOffsetX: this.shape.centralOffsetX,
                    centralOffsetY: glassThickness + sensorThickness,
                },
            ];
        }
    }

    override render() {
        /*
        Vrátí buďto jeden <RoundedRect> anebo  len(this.parts) <RoundedRect> (což je vždy dva)
        */
       
        return <>
            {!this.parts && <RoundedRect shape={this.shape} info={this.info} />}
            {this.parts && this.parts.map((shape, i) =>
                <RoundedRect key={i} shape={shape} info={this.info} />
            )}
        </>;
    }
}