import { Sensor } from "../components/Sensor";
import { ISolution } from "../models/Solution";

export function fillSolutionsEmpty(solution : ISolution) {


    /*
        If any exception states of solution are found, solve them here

    */


        


    if(!(solution.sensor.tail.centralOffset || solution.sensor.tail.centralOffset === 0)) {

        solution.sensor.tail.centralOffset = 0
    }

    solution.printing?.objects.map((obj) => {

        if(!(obj.positioning.centralOffsetX || obj.positioning.centralOffsetX === 0)){
            obj.positioning.centralOffsetX = 0
        }

        if(!(obj.positioning.centralOffsetY || obj.positioning.centralOffsetY === 0)){
            obj.positioning.centralOffsetY = 0
        }
    })
}