import { IDimensions2D, IOfsset2D, Orientation } from "./Common";

// Specifies the sensor side where the tail is attached
export enum TailAttachment {
    Top = "Top", Bottom = "Bottom", Left = "Left", Right = "Right"
}
export const allAttachments = [ TailAttachment.Top, TailAttachment.Bottom, TailAttachment.Left, TailAttachment.Right ];
export const horizontalAttachments = [ TailAttachment.Left, TailAttachment.Right ];
export const verticalAttachments = [ TailAttachment.Top, TailAttachment.Bottom ];

export function opposite(side: TailAttachment) {
    switch (side) {
        case TailAttachment.Top:
            return TailAttachment.Bottom;
        case TailAttachment.Bottom:
            return TailAttachment.Top;
        case TailAttachment.Left:
            return TailAttachment.Right;
        case TailAttachment.Right:
            return TailAttachment.Left;
    }
}

// sides that have same sign for tail central offset
export function complementary(side: TailAttachment) {
    switch (side) {
        case TailAttachment.Top:
            return TailAttachment.Left;
        case TailAttachment.Bottom:
            return TailAttachment.Right;
        case TailAttachment.Left:
            return TailAttachment.Top;
        case TailAttachment.Right:
            return TailAttachment.Bottom;
    }
}

export interface ISensor extends IDimensions2D {
    withSensor: boolean,
    isCustom: boolean,
    diagonal: number, // inches
    activeArea: IDimensions2D & IOfsset2D,
    isPassiveAreaExact: boolean,
    tail: ITail,
    cable: {
        height: number,
    },
    orientation: Orientation,
    passiveArea: {
        diagonal: number
        left: number,
        right: number,
        top: number,
        bottom: number,
    }
}

export interface ITail {
    width: number, // always the side attached to sensor
    length: number, // always the side perpendicular to sensor
    centralOffset: number, // difference between attachment side center and sensor side center
    isCustom: boolean, // helper attribute for non-admin usage
    attachment: TailAttachment,
}

export const standardSensors = [
    {
        isCustom: false,
        diagonal: 32,
        width: 718,
        height: 413,
        activeArea: {
            width: 702,
            height: 397,
            centralOffsetX: 0,
            centralOffsetY: -5,
        },
        isPassiveAreaExact: true,
        tail: {
            width: 91,
            length: 95,
            centralOffset: 0,
            isCustom: false,
            attachment: TailAttachment.Bottom,
        },
        orientation: Orientation.Landscape,
    },
    {
        isCustom: false,
        diagonal: 43,
        width: 962,
        height: 553,
        activeArea: {
            width: 944,
            height: 532,
            centralOffsetX: 0,
            centralOffsetY: -2.5
        },
        isPassiveAreaExact: true,
        tail: {
            width: 126,
            length: 125,
            isCustom: false,
            attachment: TailAttachment.Bottom
        },
        orientation: Orientation.Landscape,
    },
    {
        isCustom: false,
        diagonal: 49,
        width: 1104,
        height: 641,
        activeArea: {
            width: 1076,
            height: 606,
            centralOffsetX: 0,
            centralOffsetY: -5
        },
        isPassiveAreaExact: true,
        tail: {
            width: 148,
            length: 195,
            isCustom: false,
            attachment: TailAttachment.Bottom
        },
        orientation: Orientation.Landscape,
    },
    {
        isCustom: false,
        diagonal: 55,
        width: 1237,
        height: 712,
        activeArea: {
            width: 1213,
            height: 684,
            centralOffsetX: 0,
            centralOffsetY: -6
        },
        isPassiveAreaExact: true,
        tail: {
            width: 148,
            length: 195,
            isCustom: false,
            attachment: TailAttachment.Bottom
        },
        orientation: Orientation.Landscape,
    },
    {
        isCustom: false,
        diagonal: 65,
        width: 1460,
        height: 841,
        activeArea: {
            width: 1432,
            height: 808,
            centralOffsetX: 0,
            centralOffsetY: -7.5
        },
        isPassiveAreaExact: true,
        tail: {
            width: 234,
            length: 145,
            isCustom: false,
            attachment: TailAttachment.Bottom
        },
        orientation: Orientation.Landscape,
    },
    {
        isCustom: false,
        diagonal: 75,
        width: 1688,
        height: 967,
        activeArea: {
            width: 1656,
            height: 934,
            centralOffsetX: 0,
            centralOffsetY: -8.5
        },
        isPassiveAreaExact: true,
        tail: {
            width: 196,
            length: 155,
            isCustom: false,
            attachment: TailAttachment.Bottom
        },
        orientation: Orientation.Landscape,
    },
    {
        isCustom: false,
        diagonal: 86,
        width: 1943,
        height: 1114,
        activeArea: {
            width: 1899,
            height: 1943,
            centralOffsetX: 0,
            centralOffsetY: -16
        },
        isPassiveAreaExact: true,
        tail: {
            width: 196,
            length: 155,
            isCustom: false,
            attachment: TailAttachment.Bottom
        },
        orientation: Orientation.Landscape,
    },
] as ISensor[];

export function findStandardSensor(diagonal: number) {
    return standardSensors.find(s => s.diagonal === diagonal);
}

export function getCurrentAttachments(sensor: ISensor) {
    return horizontalAttachments.includes(sensor.tail.attachment) ? horizontalAttachments : verticalAttachments;
}

export function getAlternativeAttachments(sensor: ISensor) {
    return horizontalAttachments.includes(sensor.tail.attachment) ? verticalAttachments : horizontalAttachments;
}

export function isRotated(sensor: ISensor) {
    const standard = findStandardSensor(sensor.diagonal);
    if (sensor.isCustom || !standard)
        return false;
    return sensor.orientation !== standard.orientation;
}