import { Box, TextField } from "@mui/material";
import { FieldProps, getIn } from "formik";
import React, { HTMLInputTypeAttribute, ReactNode } from "react";
import { getOnChange, getOnChangeNumberInput, getOnChangeSelectInput } from "../models/Form";
import { StyledTextMuiField, RawTextMuiField } from "./muiFields/StyledTextMuiField";
import { StyledNotesMuiField } from "./muiFields/StyledNotesMuiField";
import { RawNumberMuiField, StyledNumberMuiField } from "./muiFields/StyledNumberMuiField";
import { StyledSelectMuiFiled } from "./muiFields/StyledSelectMuiField";

export interface IProps extends FieldProps {
    // labelKey: string,
    // namespace: string,
    label: string,
    helperText?: string,
    fullWidth?: boolean,
    type?: HTMLInputTypeAttribute,
    variant?: 'input' | 'select' | 'textarea' | 'notes',
    disabled?: boolean,
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>,
    InputProps?: any,
    paired?: boolean
    children?: ReactNode[],
    alignRight?: boolean,
    xsmd?: number[]
    step?: number
    min?: number
    max?: number
    integer: boolean
    required? : boolean
    email? : boolean
}

export interface IItemProps {
    key: string,
    value: string,
    valToStr?: (value: any) => string
}

export default function MuiField(props: IProps) {

    if(props.variant == "select") return <StyledSelectMuiFiled {...props} />
    if(props.type == "number") return <StyledNumberMuiField {...props}/>
    if(props.type == "rawNumber") return <RawNumberMuiField min={props.min} max={props.max} thisField={props.field} name={props.field.name} defaultValue={props.field.value} onChange={getOnChange(props)} integer={props.integer===true}/>
    if(props.type == "text" || props.variant == "textarea") return <StyledTextMuiField {...props} />
    if(props.type == "email" ) return <StyledTextMuiField {...props} email={true}/>
    if(props.type == "rawText") return <RawTextMuiField name={props.field.name} helperText={props.helperText} defaultValue={props.field.value} onChange={getOnChange(props)}/>
    if(props.variant == "notes") return <StyledNotesMuiField {...props} />


    return <Box color="red">
            INCORRECT FIELD type:{props.type} varinat:{props.variant} label:{props.label}
    </Box>;
}





