import { useMemo } from "react";
import { BaseComponent } from "../components/Base";
import { IArea } from "./Common";
import { TextBlock } from "./TextBlock";
import React from "react";

export interface IDescriptionProps {
    area: IArea,
    components: BaseComponent[],
}
  
export function Descriptions({ area, components }: IDescriptionProps) {

    const componentTexts = useMemo(() => components.map(c => `${c.info.id}. ${c.info.description}`.toUpperCase()), [components]);
    const colors = useMemo(() => components.map(c => c.info.color), [components]);

    return <TextBlock area={area} paragraphs={componentTexts} colors={colors} />;
}