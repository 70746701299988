import { useContext, useMemo } from "react";
import { ScaleContext } from "./Common";
import * as d3 from "d3";
import { IRoundedRectShape, getCorners, getLinePoints, getRadius } from "../models/Rectangle";
import { IComponentInfo } from "../components/Base";
import { ConstructionOutlined } from "@mui/icons-material";
import React from "react";


export interface IRoundedRectProps {
  shape: IRoundedRectShape,
  info: IComponentInfo,
}

export function RoundedRect({ shape, info }: IRoundedRectProps) {
  const scale = useContext(ScaleContext);
  /*
  Get radius vrátí ( lt : num, rt : num ...) a je  to scalenutý 
  */
  const radius = useMemo(() => getRadius(shape, scale), [shape, scale]);
  const points = useMemo(() => getLinePoints(shape, scale), [shape, scale]);
  const corners = useMemo(() => getCorners(shape, scale), [shape, scale]);
  
  /*
  .log("\n\n\n:333")
  .log(`
    delka a: ${corners[1][0] - corners[0][0]}
    delka b: ${corners[2][1] - corners[1][1]}
    width: ${shape.width}
    heigth: ${shape.height}
    pomer bodovych vzdalenosti: ${(corners[1][0] - corners[0][0])/(corners[2][1] - corners[1][1])}
    pomer shape: ${shape.width/shape.height}
    prepocet: ${shape.width/(corners[1][0] - corners[0][0])} -- ${shape.height/(corners[2][1] - corners[1][1])}
    `)*/
  const path = useMemo(() => {
    const p = d3.path();
    
    
    
    p.moveTo(...points[0])
    //p.lineTo(...[390, 450])
    p.lineTo(...points[1]);
    //p.lineTo(...corners[1]);
    p.arcTo(...corners[1], ...points[2], radius.tr);
    //p.lineTo(...points[2]);
    
    p.lineTo(...points[3]);
    p.arcTo(...corners[2], ...points[4], radius.br);
    //p.lineTo(...points[4]);
    
    p.lineTo(...points[5]);
    p.arcTo(...corners[3], ...points[6], radius.bl);
    //Toto udělá takovou vypuklinu -> arcTo udělá čáru z current bodu do prvního bodu a zastaví se {radius} před prvním bodem, udělá kruznici
    //az dokud tecna kruznice nebude zarovnana s lajnou od prvního do druhého bodu
    //p.arcTo(corners[3][0], corners[3][1]+30, ...points[6], radius.bl/3);
    //p.lineTo(...points[6]);
    
    p.lineTo(...points[7]);
    //p.lineTo(...points[0]);
    p.arcTo(...corners[0], ...points[0], radius.tl);
     
    p.closePath();
    return p;
  }, [corners, points, radius]);

  return <path fill="none" stroke={info.color ?? "currentColor"} strokeWidth="1" d={path.toString()} />
}