import { useContext, useMemo } from "react";
import * as d3 from "d3";
import * as math from "../models/Math";
import { ColorContext, ScaleContext } from "./Common";
import React from "react";


/** Adapted from https://github.com/d3/d3-shape/blob/main/src/symbol/triangle.js */
function drawHead(size: number) {
    const sqrt3 = Math.sqrt(3);
    const path = d3.path();
    const x = -Math.sqrt(size / (sqrt3 * 3));
    path.moveTo(x * 2, 0);
    path.lineTo(-x, -sqrt3 * x);
    path.moveTo(x * 2, 0);
    path.lineTo(-x, sqrt3 * x);
    return path;
}

export interface IArrowProps {
    from: [number, number],
    to: [number, number],
    start?: boolean,
    end?: boolean,
    size?: number, // canvas units
}
  
export function Arrow(props: IArrowProps) {
    const arrowSize = props.size ?? 15;

    const { posX, posY } = useContext(ScaleContext);
    const { color } = useContext(ColorContext);

    const from = [posX(props.from[0]), posY(props.from[1])] as const;
    const to = [posX(props.to[0]), posY(props.to[1])] as const;

    const path = useMemo(() => {
        const p = d3.path();
        p.moveTo(...from);
        p.lineTo(...to);
        return p;
    }, [posX, posY, props.from, props.to]);

    const headPath = useMemo(() => drawHead(arrowSize).toString(), [arrowSize]);

    const vec = math.vec(from, to);
    const length = math.distance(from, to); 
    const angle = math.toDeg(math.signedAngleBetween([1, 0], vec));
    const arrowOffset = arrowSize / 5;
    const offset = [arrowOffset * vec[0] / length, arrowOffset * vec[1] / length];

    return <>
        <path stroke={color} strokeWidth="1" d={path.toString()} />
        {props.start &&
            <path stroke={color} fill="none" d={headPath} transform={`translate(${from[0] + offset[0]} ${from[1] + offset[1]}) rotate(${angle} 0 0)`} />}
        {props.end && 
            <path stroke={color} fill="none" d={headPath} transform={`translate(${to[0] - offset[0]} ${to[1] - offset[1]}) rotate(${angle + 180} 0 0)`} />}
    </>;
}