import { Box } from "@mui/material";

export function Separator() : JSX.Element {
  return <Box 
    sx={{
      borderTop: "1px solid #EEF2F6",
      marginBlock:"4px",
      height:"1px",
    }}  
  />
}