import { ReactElement, Suspense, useContext, useEffect, useMemo, useState } from "react";
import { ScaleContext } from "./Common";
import * as d3 from "d3";
import { IRoundedRectShape, getCorners, getLinePoints, getRadius } from "../models/Rectangle";
import { IComponentInfo } from "../components/Base";
import { ConstructionOutlined } from "@mui/icons-material";
import React from "react";
import reactLogo from "../Forteslogo_REG_black.svg"
import { ReactComponent as Spritet } from "../Forteslogo_REG_black.svg"
import { request } from "http";

import SVG from 'react-inlinesvg';
import InlineSVG from "react-inlinesvg";
//import InlineSVG from 'react-inlinesvg';






export interface IImgRect {
  shape: IRoundedRectShape,
  file: File,
  canvasW : number
}

export function ImgRectangle({ shape, file, canvasW }: IImgRect) {
  
  
  
  const [svgData, setSvgData] = useState<string>("");
  
  
  

  const reader = new FileReader()
  reader.onload = async (e) => { 
    if(e.target){
      if(e.target.result){
        const text = (e.target.result)

        setSvgData(text.toString())

      }
    }
  };
  reader.readAsText(file)


  
  
  const scale = useContext(ScaleContext);
  //Get radius vrátí ( lt :  nu m, rt : nu m ...)  a je  to scalenutý 
  
  const corners = useMemo(() => getCorners(shape, scale), [shape, scale]);
  
  const imgHeight = corners[3][1] - corners[0][1]
  
  const imgPosX = ((corners[1][0] + corners[0][0])/2) - canvasW/2
  const imgPosY = ((corners[1][1]))
  

 

  //width needs to be empty, otherwise svg translates it to somewhere 
  //@ts-ignore
  var x = <InlineSVG src={svgData} height={imgHeight} width=""/> 


  return <svg x={imgPosX} y={imgPosY}>
      { (x !== undefined) && x }
    
    </svg>
    
  
}

