import { LocationDirection, Side, floatToStr } from "../models/Common";
import { ISolution } from "../models/Solution";
import { ActiveArea } from "./ActiveArea";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";

export class Sensor extends BaseComponent {
    public constructor(id: number, solution: ISolution, innerArea: IComponentShape, activeArea: ActiveArea) {
        const sensor = solution.sensor;
        const diagonal = floatToStr(sensor.passiveArea.diagonal);


        const width_passive_plus_active = Number(sensor.activeArea.width) + Number(sensor.passiveArea.left) + Number(sensor.passiveArea.right)
        const height_passive_plus_active = Number(sensor.activeArea.height) + Number(sensor.passiveArea.top) + Number(sensor.passiveArea.bottom)

        super({
            id,
            color: "#00ccff",
            description: `touch sensor ${diagonal}" ${sensor.tail.isCustom ? ' with custom tail alignment' : ''}`,
        } as IComponentInfo,
        {
            width: width_passive_plus_active,
            height: height_passive_plus_active,
            centralOffsetX: (activeArea.shape.centralOffsetX ?? 0) + (sensor.passiveArea.right - sensor.passiveArea.left)/2,
            centralOffsetY: (activeArea.shape.centralOffsetY ?? 0) + (sensor.passiveArea.bottom - sensor.passiveArea.top)/2,
        } as IComponentShape,
        {
            dimensions: {
                sides: [ Side.Bottom, Side.Right ],
                diff: true,
            },
            description: false,
            id: {
                locationDirection: LocationDirection.Inward,
                fromSide: Side.Right,
                referenceShape: innerArea,
                padding: 20,
                perpendicularOffset: -25,
            },
        } as IComponentLabels);
    }
}