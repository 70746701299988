import { IComponentShape } from "../components/Base";
import { identityScale, createDefaultScale } from "../drawing/Common";
import { IRadius, IRadiusShorthand } from "./Common";
import * as math from "./Math";

export interface IRoundedRectShape extends IComponentShape {
    radius?: IRadiusShorthand,
    drawNoRadii?: boolean
}

/**
 * Rozhodne, jestli se jedná o IComponentShape nebo IRoundedShape, který extenduje IComponentShape
 */
export function isRoundedRect(shape: IComponentShape): shape is IRoundedRectShape {
    /*
    Pokud mezi proměnnýma v shape najde radius (viz definice IRoundedRectShape) exituje radius, vrátí true
    Jinak false
    */
    return 'radius' in shape;
}

export interface IShapeBounds {
    x: number,
    _x: number, // -x
    y: number,
    _y: number, // -y
}

/**
 * Scales shape and calculates the distance from mid to bounds
 * 
 * @param shape shape který změřit
 * @param scale [scale=identityScale] scale, přes který škálovat shape
 * @returns IShapeBounds {x, _x, y, _y}
 */
export function getBounds(shape: IComponentShape, scale = identityScale): IShapeBounds {
    
    
    
    const offsetX = scale.size(shape.centralOffsetX ?? 0);
    const offsetY = scale.size(shape.centralOffsetY ?? 0);
    
    const x = scale.posX(shape.width/2) + offsetX;
    const _x = scale.posX(-shape.width/2) + offsetX;
    const y = scale.posY(shape.height/2) + offsetY;
    const _y = scale.posY(-shape.height/2) + offsetY;
    


    return { x, _x, y, _y };
}

export function getCorners(shape: IComponentShape, scale = identityScale) {
    const { x, _x, y, _y } = getBounds(shape, scale);
    return  [
        [_x, _y],
        [x, _y],
        [x, y],
        [_x, y],
    ] as const;
}

export function getSideCenters(shape: IComponentShape, scale = identityScale) {
    const corners = getCorners(shape, scale);

    return [
        math.mult(0.5, math.plus(corners[0], corners[1])),
        math.mult(0.5, math.plus(corners[1], corners[2])),
        math.mult(0.5, math.plus(corners[2], corners[3])),
        math.mult(0.5, math.plus(corners[3], corners[0])),
    ]
}

export function getLinePoints(shape: IRoundedRectShape, scale = identityScale) {
    const radius = getRadius(shape, scale);
    const { x, _x, y, _y } = getBounds(shape, scale);


    const fin = [
        [_x + radius.tl, _y], 
        [x - radius.tr, _y],
        [x, _y + radius.tr], 
        [x, y - radius.br],
        [x - radius.br, y], 
        [_x + radius.bl, y],
        [_x, y - radius.bl],
        [_x, _y + radius.tl]
    ] as const;

    return fin
}

/**
 * Scales the shape and return four values of radius of the shape
 * @param shape shape, its radius gets returned
 * @param scale how to scale the shape
 * @returns Iradius (lt: num, rt: num, ...)
 */
export function getRadius(shape: IRoundedRectShape, scale = identityScale) {
    return getFullRadius(shape.radius, scale);
}

export function componentWantsNoRaddiRendered(shape: IRoundedRectShape){
    //If shape.dontDrawRadii is null, returns false
    return shape.drawNoRadii? shape.drawNoRadii : false 
}

export function getFullRadius(radius: IRadiusShorthand | undefined, scale = identityScale): IRadius {
    const r = { tl: 0, tr: 0, bl: 0, br: 0 };
    if (!radius)
        return r;

    if (typeof radius === 'number') {
        r.tl = r.tr = r.bl = r.br = scale.size(radius);
        return r;
    }
    if ('t' in radius && 'b' in radius) {
        r.tl = r.tr = scale.size(radius.t);
        r.bl = r.br = scale.size(radius.b);
    }
    else {
        r.tl = scale.size(radius.tl);
        r.tr = scale.size(radius.tr);
        r.bl = scale.size(radius.bl);
        r.br = scale.size(radius.br);
    }
    return r;
}

export function isNonZero(radius: IRadiusShorthand) {
    if (typeof radius === 'number')
        return radius !== 0;
    if ('t' in radius && 'b' in radius)
        return radius.t || radius.b;
    return radius.tl || radius.bl || radius.tr || radius.br;
}

export function isLargerThan(a: IComponentShape, b: IComponentShape) {
    const aBounds = getBounds(a);
    const bBounds = getBounds(b);

    return (aBounds._x - bBounds._x) < -Number.EPSILON || (aBounds.x - bBounds.x) > Number.EPSILON
        || (aBounds._y - bBounds._y) < -Number.EPSILON || (aBounds.y - bBounds.y) > Number.EPSILON;
}