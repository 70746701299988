import { Box, Checkbox, CheckboxProps, FormControl, FormControlLabel, Grid, MenuItem, Switch, ThemeProvider, Typography } from "@mui/material";
import { FieldProps } from "formik";
import { getOnChange } from "../models/Form";
import { StyledMuiFieldLabel } from "./muiFields/MuiFIeldLabel";

interface ICheckboxProps extends CheckboxProps {
  name : string
}

export function CheckboxField(props: FieldProps & { label: string, disabled?: boolean, paired?: boolean, xsmd?: number[] }) : JSX.Element | null{

  let xsmd;
  if(props.xsmd === undefined || props.xsmd.length != 2) {
    xsmd = [5,7]
  } else {
    xsmd = props.xsmd
  }
    const onChange = getOnChange(props);

    if(props.disabled === true) return null

    return (
      <Grid container alignItems="center">
        <Grid item xs={12} md={xsmd[0]}>
            <StyledMuiFieldLabel label={props.label} paired={props.paired === true}/>
        </Grid>
        <Grid item xs={12} md={xsmd[1]} flexDirection="row-reverse" display="flex">
              <StyledSwitch {...props.field} disabled={props.disabled} onChange={onChange} />
        </Grid>
      </Grid>
    );
}


function StyledSwitch (props: CheckboxProps) : JSX.Element {
    return <div>
        <Switch
            sx = {{
              marginInlineStart: "fewpx",
              padding:0,
              display:"flex",
              '& .MuiSwitch-track': {
                margin:"auto",
                opacity:1,
                height:26,
                width:47,
                borderRadius:26/2,
                backgroundColor:"#EDF2F6",
                '.Mui-checked': {
                  color:"red"
                }
              },
              '& .MuiSwitch-switchBase': {
                '&.Mui-checked': {
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#250048',
                    opacity: 1,
                  },
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                }
              }
            }}
            {...props} 
            disabled={props.disabled} 
            onChange={props.onChange} 
        />
      </div>
  }


export function StyledCheckBox (props: ICheckboxProps) : JSX.Element {
  return <Grid container alignItems="center">
    <Grid>
      <Checkbox 
        {...props} 
        sx={{
          color: "#e0ddf2",
          borderRadius: "100px",
          '&.Mui-checked': {
            color: "#250048",
            
          }
        }}
        disabled={props.disabled} 
        onChange={props.onChange}
      />
    </Grid>
    <Grid>
      <Typography color="#250048" fontSize="100%" maxWidth="200px">{props.name}</Typography>
    </Grid>
  </Grid>
}