import { LocationDirection, Side } from "../models/Common";
import { ISolution } from "../models/Solution";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { PrintedBorder } from "./PrintedBorder";




export class ActiveArea extends BaseComponent {
    public constructor(id: number, solution: ISolution, printedBorder?: PrintedBorder, innerArea?: IComponentShape) {
        const activeArea = solution.sensor.activeArea;
        super({
            id,
            color: "#cc0000",
            description: `${solution.sensor.isCustom ? 'custom' : ''} active area`,
        } as IComponentInfo,
        {
            width: activeArea.width,
            height: activeArea.height,
            centralOffsetX: printedBorder?.shape.centralOffsetX, 
            centralOffsetY: printedBorder?.shape.centralOffsetY,
        } as IComponentShape,
        {
            dimensions: {
                sides: [ Side.Bottom, Side.Right ],
                diff: true,
            },
            description: false,
            id: {
                locationDirection: LocationDirection.Inward,
                fromSide: Side.Right,
                referenceShape: innerArea,
                padding: 20,
            },
        } as IComponentLabels);
    }
}