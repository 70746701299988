import { getIn } from "formik";
import { IItemProps, IProps } from "../MuiField";
import { getOnChange, getOnChangeSelectInput } from "../../models/Form";
import { Grid, MenuItem, outlinedInputClasses, Select, TextField, Typography } from "@mui/material";
import { StyledMuiFieldLabel } from "./MuiFIeldLabel";

export function StyledSelectMuiFiled(props: IProps) : JSX.Element {
    
    
    
  const disabled = props.disabled ?? false;
  const fullWidth = props.fullWidth ?? true;

  // const { t } = useTranslation();
  const form = props.form;
  const field = props.field;
  
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const onChange = getOnChangeSelectInput(props);



  return (
      <Grid container alignItems="center">
          
          <Grid item xs={12} md={5}>
              <StyledMuiFieldLabel label={props.label} paired={props.paired === true} />
          </Grid>

          <Grid item xs={12} md={7}>
              <Select
                  fullWidth={fullWidth}
                  id={field.name}
                  name={field.name}
                  type={props.type}
                  disabled={disabled}
                  value={field.value}
                  onChange={onChange}
                  onBlur={field.onBlur}
                  defaultChecked={field.checked}
                  error={touched && Boolean(errors)}
                  //select={props.variant === 'select'}
                  //inputProps={props.inputProps}
                  //InputProps={props.InputProps}
                  placeholder={props.helperText}

                  
          
                  sx={{                        
                      backgroundColor: "white",
                      [`& .${outlinedInputClasses.notchedOutline}`]: {
                          border: "1px solid #dfe0f2",
                          borderRadius: "14px",
                      },
                      
                      '&.Mui-focused':{
                          [`& .${outlinedInputClasses.notchedOutline}`]: {
                              border: "2px solid #1a0549"
                              
                          }
                      },
                      '&:hover':{
                          [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: "1px solid #dfe0f2",
                          }
                      },
                      '& .MuiOutlinedInput-input': {
                          color:"#250048",
                          padding:"0px",
                          paddingInline:"10px",
                          paddingBlock:"5px"
                      }
                  }}
              >
                  {props.children}
              </Select>
          </Grid>

      </Grid>
  );
}

export function StyledItemMuiFiled(props : IItemProps) : JSX.Element {
  
  /* Used in SolutionField.tsx */
  return (
      <MenuItem
          key={props.value}
          value={props.value}
          sx={{
              color: "#250048",
          }}>
          {props.valToStr ? props.valToStr(props.value) : props.value}
      </MenuItem>
  )
}