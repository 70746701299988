export function distance(from: readonly [number, number], to: readonly [number, number]): number {
    return Math.sqrt(Math.pow(from[0] - to[0], 2) + Math.pow(from[1] - to[1], 2));
}

export function magnitude(vec: readonly [number, number]): number {
    return distance(vec, [0, 0]);
}

export function dot(a: readonly [number, number], b: readonly [number, number]): number {
    return a[0] * b[0] + a[1] * b[1];
}

export function angleBetween(a: readonly [number, number], b: readonly [number, number]) {
    return Math.abs(signedAngleBetween(a, b));
}

export function signedAngleBetween(a: readonly [number, number], b: readonly [number, number]) {
    return Math.atan2(b[1] * a[0] - b[0] * a[1], b[0] * a[0] + b[1] * a[1]);
}

export function toDeg(radians: number): number {
    return radians * 180 / Math.PI;
}

export function vec(a: readonly [number, number], b: readonly [number, number]): [number, number] {
    return [b[0] - a[0], b[1] - a[1]];
}

export function norm(vec: readonly [number, number]): [number, number] {
    const mag = magnitude(vec);

    const indexZero = vec[0]/(mag==0 ? 1 : mag)
    const indexOne = vec[1]/(mag==0 ? 1 : mag)
    
    return [indexZero, indexOne];
}

export function plus(a: readonly [number, number], b: readonly [number, number]): [number, number] {
    return [a[0] + b[0], a[1] + b[1]];
}

export function mult(c: number, vec: readonly [number, number]): [number, number] {
    return [c * vec[0], c * vec[1]];
}

export function scale(a: readonly [number, number], b: readonly [number, number]): [number, number] {
    return [a[0] * b[0], a[1] * b[1]];
}

export function perpendicular(vec: readonly [number, number]): [number, number] {
    return [vec[1], -vec[0]];
}