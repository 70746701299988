import { useContext, useMemo } from "react";
import * as d3 from "d3";
import * as math from "../models/Math";
import { ColorContext, FontContext, IScaleContext, ScaleContext } from "./Common";
import { LocationDirection, Side, isVertical } from "../models/Common";
import { Label } from "./Label";
import { getSideCenters } from "../models/Rectangle";
import { IComponentInfo, IComponentShape } from "../components/Base";
import React from "react";


function getSideCenter(shape: IComponentShape, side: Side, scale: IScaleContext) {
    const centers = getSideCenters(shape, scale);
    switch (side) {
        case Side.Top:
            return centers[0];
        case Side.Bottom:
            return centers[2];
        case Side.Left:
            return centers[3];
        case Side.Right:
            return centers[1];
    }
    return [0, 0] as const;
}

export interface IIdProps {
    shape: IComponentShape,
	info: IComponentInfo,
	referenceShape?: IComponentShape,
    locationDirection: LocationDirection,
    fromSide: Side,
    padding?: number, // canvas units
    perpendicularOffset?: number, // canvas units
}
  
export function Id({ shape, info, referenceShape, locationDirection, fromSide, padding, perpendicularOffset }: IIdProps) {
    const s = useContext(ScaleContext);
    const { size: fontSize } = useContext(FontContext);

    const sideCenter = getSideCenter(shape, fromSide, s);

    const ref = referenceShape ?? shape;
    const refCenter = [s.posX(ref.centralOffsetX ?? 0), s.posY(ref.centralOffsetY ?? 0)] as const;
    const toRefCenter = math.vec(sideCenter, refCenter);
    const toRefCenterDist = math.distance(sideCenter, refCenter);

    const dirCoef = locationDirection === LocationDirection.Inward ? 1 : -1;
    const dirMask = isVertical(fromSide) ? [0, 1] as const : [1, 0] as const;

    const refSideCenters = getSideCenters(ref, s);
    let i = 0;
    for (; i < 4; ++i) {
        const toSide = math.vec(sideCenter, refSideCenters[i]);
        const mutualOrientation = math.dot(toSide, toRefCenter) * dirCoef;

        if (math.magnitude(toSide) < toRefCenterDist && mutualOrientation > 0)
            break;
    }
    const refSideNotFound = i === 4;

    let lineVec;
    if (refSideNotFound) {
        lineVec = math.mult(dirCoef * (padding ?? 1), math.scale(dirMask, math.norm(math.vec(sideCenter, refCenter))));
    } else {
        lineVec = math.vec(sideCenter, refSideCenters[i]);

        lineVec = math.scale(lineVec, dirMask);
        const normie = math.norm(lineVec)

        const a = math.mult((padding ?? 1), normie)
        lineVec = math.plus(lineVec, a);

    }
    
    const c = math.norm(math.perpendicular(lineVec))
    const offsetVec = math.mult(perpendicularOffset ?? 0, c);
    const lineDir = math.norm(lineVec);

    const from = math.plus(sideCenter, offsetVec);
    const to = math.plus(from, lineVec);

    const radius = 0.9 * fontSize;
    const circleCenter = math.plus(to, math.mult(radius, lineDir));
    const labelCenter = math.plus(circleCenter, [0, 0.3 * fontSize]);

    const path = useMemo(() => {
        const p = d3.path();
        p.moveTo(...from);
        p.lineTo(...to);
        return p;
    }, [from, to]); /*  */

    
    return <ColorContext.Provider value={{ color: info.color }}>
        <path stroke={info.color} strokeWidth="1" d={path.toString()} />
        <Label position={[s.posX.invert(labelCenter[0]), s.posY.invert(labelCenter[1])]}>{info.id}</Label>
        <circle fill="none" stroke={info.color} cx={circleCenter[0]} cy={circleCenter[1]} r={radius} />
    </ColorContext.Provider>;
}