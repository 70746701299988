import { ReactNode, useContext } from "react";
import { ColorContext, FontContext, ScaleContext } from "./Common";
import React from "react";

export interface ILabelProps {
    position: [number, number],
    textAnchor?: string,
    rotation?: number, // deg
    children: ReactNode
}
  
export function Label(props: ILabelProps) {
    const { posX, posY } = useContext(ScaleContext);
    const { color } = useContext(ColorContext);
    const { size: fontSize } = useContext(FontContext);

    const x = posX(props.position[0]);
    const y = posY(props.position[1]);

    const textAnchor = props.textAnchor ?? "middle";

    return <>
        <g transform={`translate(${x} ${y}) rotate(${props.rotation ?? 0} 0 0) translate(${-x} ${-y})`}>
            <text x={x} y={y} textAnchor={textAnchor} fontSize={fontSize} fill={color}>
                {props.children}
            </text>
        </g>
    </>;
}