import { IDimensions2D, Orientation } from "./Common";

export type otherThicknessType = -1;
export const otherThickness = -1;

export function thicknessToString(glass: IGlass, thickness: IGlass['thickness']) {
    if (thickness === otherThickness)
        return "Other";

    if (glass.isDouble)
        return `${thickness}+${thickness}`;
    return thickness.toString();
}

export interface IGlass extends IDimensions2D {
    thickness: number | otherThicknessType, // mm
    isDouble: boolean,
    orientation: Orientation,
}
