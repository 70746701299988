import { IDimensions2D, IOfsset2D, ISideOffsets, Side } from "./Common";

export interface IPrintedObject extends IDimensions2D {
    positioning: IOfsset2D
    bottomLogo: boolean,
    isHole: boolean,
    isTransparent: boolean,
    isCircle: boolean,
    radius: number
    title?: string,
    file: any,
    colors: string[],
    wImg: boolean, // RAL 
    formHidden: boolean
}


//export const defaultLogo = { width: 1, height: 1, isHole: false, isTransparent: false, colors: ['']};
export const defaultLogo = { width: 10, height: 10, positioning: {centralOffsetX: 0, centralOffsetY: 0} as IOfsset2D, bottomLogo: false, isHole: false, isTransparent: false, isCircle: false, radius: 5, colors: [''], file: null, wImg: false, title: "Object 1", formHidden: false};

export interface IPrinting {
    withPrinting: boolean,
    withLogo: boolean,
    frameThickness: ISideOffsets,
    objects: IPrintedObject[],
}