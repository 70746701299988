import { useContext } from "react";
import { ColorContext, ScaleContext } from "./Common";
import { Arrow } from "./Arrow";
import { Label } from "./Label";
import { IComponentInfo, IComponentShape } from "../components/Base";
import { IShapeBounds, getBounds } from "../models/Rectangle";
import { ISideOffsets, Side, floatToStr, isVertical } from "../models/Common";
import * as math from "../models/Math";
import React from "react";


type IDiff = {
	[Property in keyof IShapeBounds]: boolean;
}

interface IMeasures {
	from: [number, number],
	to: [number, number],
	length: number,
}

interface IMeasuredSegmentProps {
	measures: IMeasures,
	maxTickLength: number,
	offsetSize: number,
	hideFromTick?: boolean,
	hideToTick?: boolean,
}
export function MeasuredSegment({ measures, maxTickLength, offsetSize, hideFromTick, hideToTick }: IMeasuredSegmentProps) {
	const scale = useContext(ScaleContext);

	maxTickLength = scale.size.invert(maxTickLength);
	const arrowSize = 15;
	const tooShort = math.distance(measures.from, measures.to) <= 2 * 15;

	const trueRotation = math.toDeg(Math.atan2(measures.to[1] - measures.from[1], measures.to[0] - measures.from[0]));
	const rotation = trueRotation === 180 ? 0 : trueRotation;
	const fontCenterCorrection = trueRotation === 180 ? -0.5 * maxTickLength : 0;

	const vec = math.norm(math.vec(measures.from, measures.to));
	const perpend = math.mult(-1, math.perpendicular(vec));

	const heightVec = math.mult(maxTickLength, perpend);
	const tickVec = math.mult(tooShort ? 0.45 * maxTickLength : maxTickLength, perpend);
	const offsetVec = math.mult(offsetSize, perpend);
	
	let labelCenter: [number, number];
	labelCenter = [(measures.from[0] + measures.to[0]) / 2, (measures.from[1] + measures.to[1]) / 2];
	labelCenter = math.plus(math.plus(labelCenter, heightVec), math.mult(fontCenterCorrection, perpend));

	const tickFrom = { from: math.plus(measures.from, tickVec), to: measures.from };
	const tickTo = { from: math.plus(measures.to, tickVec), to: measures.to };

	const preFrom = math.plus(measures.from, math.mult(-1.5 * arrowSize, vec));
	const postTo = math.plus(measures.to, math.mult(1.5 * arrowSize, vec));

	return <g transform={`translate(${offsetVec[0]} ${offsetVec[1]})`}>

		<Arrow from={measures.from} to={measures.to} size={arrowSize} start={!tooShort} end={!tooShort} />
		<Label position={labelCenter} rotation={rotation}>{floatToStr(measures.length)}</Label>

		{!hideFromTick && <Arrow {...tickFrom} />}
		{!hideToTick && <Arrow {...tickTo} />}

		{tooShort && <>
			{!hideFromTick && <Arrow from={preFrom} to={measures.from} size={arrowSize} end />}
			{!hideToTick && <Arrow from={postTo} to={measures.to} size={arrowSize} end />}
		</>}
	</g>;
}

interface IMeasuredSideProps {
	side: Side,
	offset: ISideOffsets,
	bounds: IShapeBounds,
	referenceBounds: IShapeBounds,
	diffs: IDiff,
	maxTickLength: number,
}
export function MeasuredSide({ side, offset, bounds, referenceBounds, diffs, maxTickLength }: IMeasuredSideProps) {

	const diff = isVertical(side) ? diffs.x : diffs.y;
	const diff_ = isVertical(side) ? diffs._x : diffs._y;

	const mainFirst = { from: [0, 0], to: [0, 0], length: 0 } as IMeasures;
	const mainMid = { from: [0, 0], to: [0, 0], length: 0 } as IMeasures;
	const mainLast = { from: [0, 0], to: [0, 0], length: 0 } as IMeasures;

	let offsetSize: number;

	const changeDirection = (measures: IMeasures) => {
		const tmp = measures.from;
		measures.from = measures.to;
		measures.to = tmp;
	}

	if (isVertical(side)) {
		const y = side === Side.Top ? referenceBounds._y : referenceBounds.y;
		offsetSize = side === Side.Top ? offset.t - 1.5 * maxTickLength : -offset.b - 1.5 * maxTickLength;

		mainFirst.from = [referenceBounds._x, y];
		mainFirst.to = [bounds._x, y];
		mainFirst.length = bounds._x - referenceBounds._x;
		
		mainMid.from = [bounds._x, y];
		mainMid.to = [bounds.x, y];
		mainMid.length = bounds.x - bounds._x;
		
		mainLast.from = [bounds.x, y];
		mainLast.to = [referenceBounds.x, y];
		mainLast.length = referenceBounds.x - bounds.x;
	} else {
		const x = side === Side.Left ? referenceBounds._x : referenceBounds.x;
		offsetSize = side === Side.Left ? offset.l - 1.5 * maxTickLength : -offset.r - 1.5 * maxTickLength;

		mainFirst.from = [x, referenceBounds._y];
		mainFirst.to = [x, bounds._y];
		mainFirst.length = bounds._y - referenceBounds._y;
		
		mainMid.from = [x, bounds._y];
		mainMid.to = [x, bounds.y];
		mainMid.length = bounds.y - bounds._y;

		mainLast.from = [x, bounds.y];
		mainLast.to = [x, referenceBounds.y];
		mainLast.length = referenceBounds.y - bounds.y;
	}

	if (side === Side.Bottom || side === Side.Left) {
		changeDirection(mainFirst);
		changeDirection(mainMid);
		changeDirection(mainLast);
	}

	return <g>
		<MeasuredSegment measures={mainMid} {...{maxTickLength, offsetSize}} hideFromTick={diff_} hideToTick={diff} />
		{diff_ && <MeasuredSegment measures={mainFirst} {...{maxTickLength, offsetSize}} />}
		{diff && <MeasuredSegment measures={mainLast} {...{maxTickLength, offsetSize}} />}
	</g>;
}


export interface IDimensionsProps {
	measuredShape: IComponentShape,
	measuredInfo: IComponentInfo,
	referentialShape: IComponentShape,
	componentBounds: IShapeBounds, // total bounds of all components
	maxTickLength: number, // canvas units
	offset: ISideOffsets, // canvas units
	sides: Side[],
	diff: boolean,
}
  
export function Dimensions({ measuredShape: shape, measuredInfo: info, referentialShape, componentBounds, maxTickLength, offset: o, sides, diff }: IDimensionsProps) {


	const referenceBounds = getBounds(referentialShape);
	const bounds = getBounds(shape);
	const { size } = useContext(ScaleContext);
	
	const diffs = {
		_x: diff && referenceBounds._x < bounds._x,
		x: diff && bounds.x < referenceBounds.x,
		_y: diff && referenceBounds._y < bounds._y,
		y: diff && bounds.y < referenceBounds.y
	};

	const offset = {
		t: o.t - size(-componentBounds._y + referenceBounds._y),
		b: o.b + size(componentBounds.y - referenceBounds.y),
		l: o.l - size(-componentBounds._x + referenceBounds._x),
		r: o.r + size(componentBounds.x - referenceBounds.x)
	};

	return <>
		<g>
			<ColorContext.Provider value={{ color: info.color }}>
				{sides.includes(Side.Top) && <MeasuredSide side={Side.Top} {...{ bounds, referenceBounds, offset, maxTickLength, diffs }}/>}
				{sides.includes(Side.Bottom) && <MeasuredSide side={Side.Bottom} {...{ bounds, referenceBounds, offset, maxTickLength, diffs }}/>}
				{sides.includes(Side.Right) && <MeasuredSide side={Side.Right} {...{ bounds, referenceBounds, offset, maxTickLength, diffs }}/>}
				{sides.includes(Side.Left) && <MeasuredSide side={Side.Left} {...{ bounds, referenceBounds, offset, maxTickLength, diffs }}/>}
			</ColorContext.Provider>
		</g>
	</>
}