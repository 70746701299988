export enum ProductType {
    FortouchO = "FORTOUCH O",
    FortouchS = "FORTOUCH S",
    FortouchC = "FORTOUCH C",
    OpticalBonding = "Optical bonding",
}
export const productTypes = [ ProductType.FortouchO, ProductType.FortouchC, ProductType.FortouchS, ProductType.OpticalBonding ];

export enum ProductUsage {
    Outdoor = "Outdoor", Indoor = "Indoor", SemiOutdoor = "Semi-outdoor"
}
export const productUsages = [ ProductUsage.Indoor, ProductUsage.Outdoor, ProductUsage.SemiOutdoor ];

export interface IProduct {
    type: ProductType,
    usage: ProductUsage,
    pieces: number,
    notes: string,
    title: string,
}
