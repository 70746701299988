export enum AspectRatio {
    r16_9 = "16:9", r4_3 = "4:3", r5_4 = "5:4", Other = "Other"
}
export const aspectRatios = [ AspectRatio.r16_9, AspectRatio.r4_3, AspectRatio.r5_4, AspectRatio.Other ];

export enum DisplayConnection {
    LVDS = "LVDS", AdBoard = "AdBoard", Unknown = "Not sure"
}
export const displayConnections = [ DisplayConnection.LVDS, DisplayConnection.AdBoard, DisplayConnection.Unknown ];

export interface IDisplay {
    luminance: number, // nits
    aspectRatio: AspectRatio,
    connection: DisplayConnection,
}
