import { ISideOffsets, LocationDirection, Side } from "../models/Common";
import { TailAttachment, horizontalAttachments } from "../models/Sensor";
import { ISolution } from "../models/Solution";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { Sensor } from "./Sensor";

export class Sticker extends BaseComponent {
    public constructor(id: number, solution: ISolution, sensor: Sensor) {
        
        const tail = solution.sensor.tail;
        // decide how to draw the tail (width/length apply for vertical attachments):
        const tailRotated = tail.attachment === TailAttachment.Left || tail.attachment === TailAttachment.Right;
        
        const dimensionSide = horizontalAttachments.includes(tail.attachment) ? Side.Left : Side.Top;
        const labelSide = dimensionSide === Side.Left ? Side.Top : Side.Left;
        const glass = solution.glass
        let printedBorder = solution.printing?.frameThickness
        if(!printedBorder || solution.printing?.withPrinting === false) printedBorder = {l : 0, r : 0, t : 0, b : 0} as ISideOffsets
        /*
        const labelSide;
        if(dimensionSide === Side.Left){
            labelSide = Side.Top
        }else {
            labelSide = Side.Left
        }
        */

        let scox = 0, scoy = 0; // central offsets

        switch (solution.sensor.tail.attachment) {
            case TailAttachment.Top:
                scoy = -0.5 * sensor.shape.height + (sensor.shape.centralOffsetY ?? 0) - 0.5 * tail.length;
                scox = (printedBorder.l - printedBorder.r) / 2 * (printedBorder.l < printedBorder.r ? 1 : -1) + tail.centralOffset ?? 0
                break;
            case TailAttachment.Bottom:
                scoy = 0.5 * sensor.shape.height + (sensor.shape.centralOffsetY ?? 0) + 0.5 * tail.length;
                scox = (printedBorder.l - printedBorder.r) / 2 * (printedBorder.l < printedBorder.r ? 1 : -1) + tail.centralOffset ?? 0
                break;
            case TailAttachment.Left:
                scox = -0.5 * sensor.shape.width + (sensor.shape.centralOffsetX ?? 0) - 0.5 * tail.length;
                scoy = (printedBorder.t - printedBorder.b) / 2 * (printedBorder.t < printedBorder.b ? 1 : -1) + tail.centralOffset ?? 0
                break;
            case TailAttachment.Right:
                scox = 0.5 * sensor.shape.width + (sensor.shape.centralOffsetX ?? 0) + 0.5 * tail.length;
                scoy = (printedBorder.t - printedBorder.b) / 2 * (printedBorder.t < printedBorder.b ? 1 : -1) + tail.centralOffset ?? 0
                break;
        }

        super({
            id: id,
            color: "#00ccff",
            description: "PET part of the tail", // with serial number and foil size stickers",
        } as IComponentInfo,
        {
            width: tailRotated ? tail.length : tail.width,
            height: tailRotated ? tail.width : tail.length,
            centralOffsetX: scox,
            centralOffsetY: scoy,
        } as IComponentShape,
        {
            dimensions: {
                sides: [Side.Top, Side.Right],//sides: [ dimensionSide ],
                diff: true,
            },
            description: false,
            id: {
                locationDirection: LocationDirection.Outward,
                fromSide: Side.Bottom,
                padding: 10,
            },
        } as IComponentLabels);
    }
}