import { createImmediatelyInvokedFunctionExpression } from "typescript";
import { Side, IOfsset2D } from "../models/Common";
import { IGlass } from "../models/Glass";
import { IPrintedObject } from "../models/Printing";
import { IRoundedRectShape } from "../models/Rectangle";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base"


export class PrintedLogo extends BaseComponent{
    public constructor(id: number, solutionOfLogo: IPrintedObject, glass: IGlass) {
        const isHole = solutionOfLogo.isHole
        const isTransparent = solutionOfLogo.isTransparent


        var heightOfLogo : number
        var widthOfLogo : number
        var radiusOfLogo : number | null
        if(solutionOfLogo.isCircle) {
            heightOfLogo = Number(solutionOfLogo.radius*2)
            widthOfLogo = Number(solutionOfLogo.radius*2)
            radiusOfLogo = Number(solutionOfLogo.radius)
        }else {
            heightOfLogo = Number(solutionOfLogo.height)
            widthOfLogo = Number(solutionOfLogo.width)
            radiusOfLogo = null
        }



        let offsetY = 0
        if(solutionOfLogo.bottomLogo === true) {
            offsetY = (Number(glass.height)/2 - Number(heightOfLogo)/2) - Number(solutionOfLogo.positioning.centralOffsetY)
        }else {
            offsetY = (-Number(glass.height)/2 + Number(heightOfLogo)/2) + Number(solutionOfLogo.positioning.centralOffsetY)
        }

        let description = solutionOfLogo.title
        super({
            id,
            color: "#000",
            description,
            img: solutionOfLogo.wImg? solutionOfLogo.file : null
        } as IComponentInfo,
        {
            width: widthOfLogo,
            height: heightOfLogo,
            centralOffsetX: Number(solutionOfLogo.positioning.centralOffsetX),
            centralOffsetY: offsetY,
            radius : radiusOfLogo,
            drawNoRadii: true
        } as IRoundedRectShape,
        {
            dimensions: {
                sides: [  ],
                diff: false,
            },
            description: false,
        } as IComponentLabels);
    }
}